import { ref, onMounted, onBeforeUnmount } from 'vue'
import dayjs from 'dayjs'
import {
  updateAccountBill,
  batchUpdateAccountBill,
  exportAccountBill,
  type AccountBillItem,
  type AccountBillUpdateParams,
} from '@/api/billApi'
import { message, Modal } from 'ant-design-vue'
import { awaitWrap, handleExportFile, OK } from '@/common'
import { TablePaginationConfig } from 'ant-design-vue'
import type { SorterResult } from 'ant-design-vue/es/table/interface'

// 状态映射
export type StatusMapType = {
  [key: string]: {
    text: string
    color: string
  }
}

export type ReceivedStatus = 0 | 10 | 20 | 30
export type ReasonCode = '0' | '10' | '20' | '30' | '40' | '41' | '50' | '60' | '70' | '80' | '90'

export const statusMap: StatusMapType = {
  '0': {
    text: '待确认',
    color: 'default',
  },
  '10': {
    text: '未收款',
    color: 'warning',
  },
  '20': {
    text: '正常收款',
    color: 'success',
  },
  '30': {
    text: '异常收款',
    color: 'error',
  },
}

export const reasonMap: Record<ReasonCode, string> = {
  '10': '未维护收款信息',
  '20': '封号',
  '30': '无合适收款账户',
  '40': '未及时维护收款信息，推迟下月打款',
  '41': '结算金额未满40美金',
  '50': '收款信息有误',
  '60': '其他',
  '70': '汇率精度问题',
  '80': '账号存在历史余额',
  '90': '预扣税未计算',
  '0': '-',
}

export const columns = [
  {
    title: '结算月份',
    dataIndex: 'billMonth',
    key: 'billMonth',
    width: 100,
    fixed: 'left',
  },
  {
    title: '账号名称',
    dataIndex: 'accountName',
    key: 'accountName',
    width: 120,
    fixed: 'left',
  },
  {
    title: '收款主体',
    dataIndex: 'collectionSubject',
    key: 'collectionSubject',
    width: 120,
    fixed: 'left',
    customRender: ({ text }: { text: string }) => {
      return text || '-'
    },
  },
  {
    title: '收款银行',
    dataIndex: 'receivingBank',
    key: 'receivingBank',
    width: 120,
    customRender: ({ text }: { text: string }) => {
      return text || '-'
    },
  },
  {
    title: '银行卡后四位',
    dataIndex: 'bankCardFourDigits',
    key: 'bankCardFourDigits',
    width: 120,
    customRender: ({ text }: { text: string }) => {
      return text || '-'
    },
  },
  {
    title: '结算币种',
    dataIndex: 'currency',
    key: 'currency',
    width: 100,
  },
  {
    title: '余额',
    dataIndex: 'balance',
    key: 'balance',
    width: 100,
    sorter: true,
    customRender: ({ text }: { text: string }) => {
      return text ? Number(text).toFixed(2) : '-'
    },
  },
  {
    title: '预估收入',
    dataIndex: 'estimatedRevenue',
    key: 'estimatedRevenue',
    customTitle: true,
    tooltip: '以每月一号汇率估算',
    width: 120,
    sorter: true,
    customRender: ({ text }: { text: string }) => {
      return text ? Number(text).toFixed(2) : '-'
    },
  },
  {
    title: '预计收款时间',
    dataIndex: 'expectedReceiptTime',
    key: 'expectedReceiptTime',
    width: 160,
    customRender: ({ text }: { text: string }) => {
      return text || '-'
    },
  },
  {
    title: '收入',
    dataIndex: 'actualInvoiceAmount',
    key: 'actualInvoiceAmount',
    customTitle: true,
    tooltip: '以苹果结算汇率计算',
    width: 160,
    sorter: true,
    customRender: ({ text }: { text: string }) => {
      return text ? Number(text).toFixed(2) : '-'
    },
  },
  {
    title: '预估收款金额',
    dataIndex: 'expectReceivedAmount',
    key: 'expectReceivedAmount',
    customTitle: true,
    tooltip: '预估收款金额=余额+收入',
    width: 140,
    sorter: true,
    customRender: ({ text }: { text: string }) => {
      return text ? Number(text).toFixed(2) : '-'
    },
  },
  {
    title: '实际收款金额',
    dataIndex: 'actualReceivedAmount',
    key: 'actualReceivedAmount',
    width: 140,
    sorter: true,
    customRender: ({ text }: { text: string }) => {
      return text ? Number(text).toFixed(2) : '-'
    },
  },
  {
    title: '误差',
    dataIndex: 'receivedDiff',
    key: 'receivedDiff',
    width: 140,
    customTitle: true,
    sorter: true,
    tooltip: '误差=(实际收款-预估收款)/预估收款',
  },
  {
    title: '实际收款时间',
    dataIndex: 'actualReceiptTime',
    key: 'actualReceiptTime',
    width: 160,
    customRender: ({ text }: { text: string }) => {
      return text || '-'
    },
  },
  {
    title: '收款状态',
    dataIndex: 'receivedStatus',
    key: 'receivedStatus',
    width: 120,
  },
  {
    title: '收款异常原因',
    dataIndex: 'receivedResMsg',
    key: 'receivedResMsg',
    // width: 120,
  },
]

interface UseSettlementTableProps {
  data: AccountBillItem[]
  pagination?: TablePaginationConfig
  searchParams?: {
    startMonthDate: string
    endMonthDate: string
    accountIdList: number[]
    payChnl: string
  }
}

type EmitType = {
  (event: 'pagination-change', page: number, pageSize: number): void
  (event: 'sort-change', sortField: string, ascending: boolean): void
  (event: 'update-list'): void
}

export const useSettlementTable = (props: UseSettlementTableProps, emit: EmitType) => {
  const tableHeight = ref(0)
  const modalVisible = ref(false)
  const otherReasonModalVisible = ref<boolean>(false)
  const selectedRowKeys = ref<string[]>([])
  const selectedRows = ref<AccountBillItem[]>([])
  const isBatchOperation = ref(false)
  const currentRecord = ref<AccountBillItem>({
    id: '',
    pay_chnl: '',
    dateTime: '',
    accountId: 0,
    accountName: '',
    currency: '',
    collectionSubject: '',
    receivingBank: '',
    bankCardFourDigits: '',
    estimatedRevenue: '',
    expectedReceiptTime: '',
    actualInvoiceAmount: '',
    actualReceivedAmount: '',
    actualReceiptTime: '',
    receivedStatus: null,
    receivedResCode: null,
    receivedResMsg: null,
    createTime: '',
    updateTime: '',
    isNeedExternalSettlement: 0,
    balance: '',
    expectReceivedAmount: '',
    receivedDiff: '',
    receivedResExtInfo: {
      imageList: [],
      receivedResList: [],
    },
  })
  const originalRecord = ref<AccountBillItem>({
    id: '',
    pay_chnl: '',
    dateTime: '',
    accountId: 0,
    accountName: '',
    currency: '',
    collectionSubject: '',
    receivingBank: '',
    bankCardFourDigits: '',
    estimatedRevenue: '',
    expectedReceiptTime: '',
    actualInvoiceAmount: '',
    actualReceivedAmount: '',
    actualReceiptTime: '',
    receivedStatus: null,
    receivedResCode: null,
    receivedResMsg: null,
    createTime: '',
    updateTime: '',
    isNeedExternalSettlement: 0,
    balance: '',
    expectReceivedAmount: '',
    receivedDiff: '',
    receivedResExtInfo: {
      imageList: [],
      receivedResList: [],
    },
  })
  const textareaRef = ref<any>(null)
  const editFieldModalVisible = ref<boolean>(false)
  const editingField = ref<
    'expectedReceiptTime' | 'actualReceivedAmount' | 'actualReceiptTime' | 'balance' | 'receivedStatus' | null
  >(null)
  const editingValue = ref<dayjs.Dayjs | number | null>(null)
  const editFieldTitle = ref<string>('')
  const fileList = ref<any[]>([])
  const previewVisible = ref(false)
  const previewImage = ref('')
  const previewTitle = ref('')

  const changePage = (page: number, pageSize: number) => {
    emit('pagination-change', page, pageSize)
  }

  const handleImportSettlement = () => {
    modalVisible.value = true
  }

  const handleExportAccount = async () => {
    try {
      if (!props.searchParams) {
        message.warn('请选择账号')
        return
      }
      handleExportFile(
        await exportAccountBill({
          startMonthDate: props.searchParams.startMonthDate,
          endMonthDate: props.searchParams.endMonthDate,
          accountIdList: props.searchParams.accountIdList,
          payChnl: props.searchParams.payChnl,
        }),
        `财务结算账单`,
        'xlsx',
      )
    } catch (error) {
      console.log('[ error ] >', error)
    }
  }

  const handleEditCustomReason = (record: AccountBillItem) => {
    currentRecord.value = {
      ...record,
      receivedResExtInfo: {
        imageList: record.receivedResExtInfo?.imageList || [],
        receivedResList:
          record.receivedResExtInfo?.receivedResList?.map((item) => ({
            ...item,
            receivedResCode: item.receivedResCode?.toString(),
          })) || [],
      },
    }
    originalRecord.value = {
      ...record,
      receivedResExtInfo: {
        imageList: record.receivedResExtInfo?.imageList || [],
        receivedResList: record.receivedResExtInfo?.receivedResList || [],
      },
    }
    fileList.value = (record.receivedResExtInfo?.imageList || []).map((url, index) => ({
      uid: `-${index}`,
      name: url.split('/').pop() || `image-${index}`,
      status: 'done',
      url: url,
    }))
    otherReasonModalVisible.value = true
  }

  const handleOtherReasonConfirm = async ({
    currentRecord,
    fileUrls,
  }: {
    currentRecord: AccountBillItem
    fileUrls: string[]
  }) => {
    if (
      currentRecord.receivedResExtInfo?.receivedResList?.some((item) => String(item.receivedResCode) === '60') &&
      !currentRecord?.receivedResMsg
    ) {
      return message.error('请输入异常原因')
    }

    try {
      // 如果是批量操作
      if (isBatchOperation.value && selectedRowKeys.value.length > 0) {
        const [err, res]: any = await awaitWrap(
          batchUpdateAccountBill({
            ids: selectedRowKeys.value,
            receivedResExtInfo: {
              imageList: fileUrls,
              receivedResList: currentRecord.receivedResExtInfo?.receivedResList || [],
            },
          }),
        )

        if (err) {
          console.log('[ err ] >', err)
        } else if (res && res.code === OK) {
          message.success(`成功更新${selectedRowKeys.value.length}条记录`)
          emit('update-list')
          otherReasonModalVisible.value = false
          selectedRowKeys.value = []
          // 重置批量操作状态
          isBatchOperation.value = false
        }
      } else {
        // 单条记录修改
        const [err, res]: any = await awaitWrap(
          updateAccountBill({
            id: currentRecord.id,
            receivedResExtInfo: {
              imageList: fileUrls,
              receivedResList: currentRecord.receivedResExtInfo?.receivedResList || [],
            },
          }),
        )
        if (err) {
          console.log('[ err ] >', err)
          return
        }
        if (res && res.code === OK) {
          message.success('更新成功')
          emit('update-list')
          otherReasonModalVisible.value = false
        }
      }
    } catch (error) {
      console.log('[ error ] >', error)
    }
  }

  const handleOtherReasonCancel = () => {
    otherReasonModalVisible.value = false
    // 重置批量操作状态
    if (isBatchOperation.value) {
      isBatchOperation.value = false
    }
  }

  const onSelectChange = (keys: string[], rows: AccountBillItem[]) => {
    selectedRowKeys.value = keys
    selectedRows.value = rows
  }

  const handleEditField = (
    record: AccountBillItem,
    field: 'expectedReceiptTime' | 'actualReceivedAmount' | 'actualReceiptTime' | 'balance',
  ) => {
    isBatchOperation.value = false
    currentRecord.value = { ...record }
    editingField.value = field
    if (field === 'expectedReceiptTime' || field === 'actualReceiptTime') {
      editingValue.value = record[field] ? dayjs(record[field]) : null
    } else {
      editingValue.value = record[field] && typeof record[field] === 'string' ? parseFloat(record[field]) : null
    }
    editFieldTitle.value =
      field === 'expectedReceiptTime'
        ? '预计收款时间'
        : field === 'actualReceiptTime'
        ? '实际收款时间'
        : field === 'balance'
        ? '账号余额'
        : '实际收款金额'
    editFieldModalVisible.value = true
  }

  const handleEditFieldConfirm = async () => {
    if (isBatchOperation.value && selectedRows.value.length > 0 && editingField.value && editingValue.value !== null) {
      try {
        // 收集所有选中行的id
        const ids = selectedRows.value.map((record) => record.id)

        // 格式化值
        let formattedValue: string | null = null
        if (editingField.value === 'expectedReceiptTime' || editingField.value === 'actualReceiptTime') {
          formattedValue = (editingValue.value as dayjs.Dayjs).format('YYYY-MM-DD')
        } else {
          formattedValue = String(editingValue.value)
        }

        // 使用ids进行批量更新
        const updateParams: any = {
          ids, // 直接使用ids数组
        }

        if (editingField.value === 'expectedReceiptTime') {
          updateParams.expectedReceiptTime = formattedValue
        } else if (editingField.value === 'actualReceivedAmount') {
          updateParams.actualReceivedAmount = formattedValue
        } else if (editingField.value === 'actualReceiptTime') {
          updateParams.actualReceiptTime = formattedValue
        } else if (editingField.value === 'balance') {
          updateParams.balance = formattedValue
        } else if (editingField.value === 'receivedStatus') {
          updateParams.receivedStatus = Number(formattedValue) as ReceivedStatus
        }

        // 调用一次API进行批量更新
        const [err, res]: any = await awaitWrap(batchUpdateAccountBill(updateParams))

        if (err) {
          console.log('[ err ] >', err)
          return
        }

        if (res && res.code === OK) {
          // 更新本地数据
          selectedRows.value.forEach((record) => {
            if (editingField.value === 'expectedReceiptTime') {
              record.expectedReceiptTime = formattedValue as string
            } else if (editingField.value === 'actualReceivedAmount') {
              record.actualReceivedAmount = formattedValue as string
            } else if (editingField.value === 'actualReceiptTime') {
              record.actualReceiptTime = formattedValue as string
            } else if (editingField.value === 'balance') {
              record.balance = formattedValue as string
            } else if (editingField.value === 'receivedStatus') {
              record.receivedStatus = Number(formattedValue) as ReceivedStatus
            }
          })

          message.success(`成功更新${selectedRows.value.length}条记录`)
          emit('update-list')
          selectedRowKeys.value = []
        }
      } catch (error) {
        console.log('[ error ] >', error)
      }
    } else if (currentRecord.value && editingField.value && editingValue.value !== null) {
      try {
        const updateParams: AccountBillUpdateParams = {
          id: currentRecord.value.id,
        }

        if (editingField.value === 'expectedReceiptTime') {
          updateParams.expectedReceiptTime = (editingValue.value as dayjs.Dayjs).format('YYYY-MM-DD')
        } else if (editingField.value === 'actualReceivedAmount') {
          updateParams.actualReceivedAmount = String(editingValue.value)
        } else if (editingField.value === 'actualReceiptTime') {
          updateParams.actualReceiptTime = (editingValue.value as dayjs.Dayjs).format('YYYY-MM-DD')
        } else if (editingField.value === 'balance') {
          updateParams.balance = String(editingValue.value)
        } else if (editingField.value === 'receivedStatus') {
          updateParams.receivedStatus = Number(editingValue.value) as ReceivedStatus
        }

        const [err, res]: any = await awaitWrap(updateAccountBill(updateParams))
        if (err) {
          console.log('[ err ] >', err)
          return
        }
        if (res && res.code === OK) {
          const record = props.data.find((item: AccountBillItem) => item.id === currentRecord.value?.id)
          if (record) {
            if (editingField.value === 'expectedReceiptTime') {
              record.expectedReceiptTime = updateParams.expectedReceiptTime ?? record.expectedReceiptTime
            } else if (editingField.value === 'actualReceivedAmount') {
              record.actualReceivedAmount = updateParams.actualReceivedAmount ?? record.actualReceivedAmount
            } else if (editingField.value === 'actualReceiptTime') {
              record.actualReceiptTime = updateParams.actualReceiptTime ?? record.actualReceiptTime
            } else if (editingField.value === 'balance') {
              record.balance = updateParams.balance ?? record.balance
            } else if (editingField.value === 'receivedStatus') {
              record.receivedStatus = updateParams.receivedStatus ?? record.receivedStatus
            }
          }
          emit('update-list')
          message.success('更新成功')
        }
      } catch (error) {
        console.log('[ error ] >', error)
      }
    }
    editFieldModalVisible.value = false
  }

  const handleEditFieldCancel = () => {
    editFieldModalVisible.value = false
  }

  const calculateTableHeight = () => {
    // 获取.revenue-header元素的高度并设置CSS变量
    const revenueHeaderEl = document.querySelector('.revenue-header')
    const revenueHeaderHeight = revenueHeaderEl ? revenueHeaderEl.clientHeight : 0
    document.documentElement.style.setProperty('--revenue-header-height', `${revenueHeaderHeight}px`)

    // 获取settlement-table的总高度
    const settlementTableEl = document.querySelector('.settlement-table')
    if (!settlementTableEl) return

    // 获取表格头部和分页器的高度
    const tableHeaderEl = settlementTableEl.querySelector('.table-header')
    const paginationEl = settlementTableEl.querySelector('.pagination-container')

    const tableHeaderHeight = tableHeaderEl?.clientHeight || 0
    const paginationHeight = paginationEl?.clientHeight || 0

    // 表格内容区高度 = settlement-table高度 - 表格头部高度 - 分页器高度 - padding(上下各24px)
    tableHeight.value = settlementTableEl.clientHeight - tableHeaderHeight - paginationHeight - 48 - 24

    document.documentElement.style.setProperty('--table-height', `${tableHeight.value}px`)
  }

  const handleStatusChange = async (value: ReceivedStatus, record: AccountBillItem) => {
    try {
      const [err, res]: any = await awaitWrap(
        updateAccountBill({
          id: record.id,
          receivedStatus: value,
        }),
      )
      if (err) {
        console.log('[ err ] >', err)
        return
      }
      if (res && res.code === OK) {
        record.receivedStatus = value
        message.success('更新成功')
        emit('update-list')
      }
    } catch (error) {
      console.log('[ error ] >', error)
    }
  }

  const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: SorterResult<AccountBillItem>) => {
    // 处理分页变化
    if (pagination.current && pagination.pageSize) {
      emit('pagination-change', pagination.current, pagination.pageSize)
    }

    // 处理排序变化
    if (sorter && sorter.field) {
      emit('sort-change', sorter.field as string, sorter.order === 'ascend')
    }
  }

  onMounted(() => {
    calculateTableHeight()
    window.addEventListener('resize', calculateTableHeight)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', calculateTableHeight)
  })

  // 批量修改账号余额
  const handleBatchModify = () => {
    if (selectedRowKeys.value.length === 0) {
      message.warning('请至少选择一条记录')
      return
    }

    isBatchOperation.value = true
    editingField.value = 'balance'
    editingValue.value = null
    editFieldTitle.value = '批量修改账号余额'
    editFieldModalVisible.value = true
  }

  // 批量修改预计回款时间
  const handleBatchModifyStatus = () => {
    if (selectedRowKeys.value.length === 0) {
      message.warning('请至少选择一条记录')
      return
    }

    isBatchOperation.value = true
    editingField.value = 'expectedReceiptTime'
    editingValue.value = null
    editFieldTitle.value = '批量修改预计收款时间'
    editFieldModalVisible.value = true
  }

  // 批量修改回款状态
  const handleBatchUpdateStatus = () => {
    if (selectedRowKeys.value.length === 0) {
      message.warning('请至少选择一条记录')
      return
    }

    isBatchOperation.value = true
    editingField.value = 'receivedStatus'
    editingValue.value = null
    editFieldTitle.value = '批量修改收款状态'
    editFieldModalVisible.value = true
  }

  // 批量修改回款异常原因
  const handleBatchUpdateExpected = () => {
    if (selectedRowKeys.value.length === 0) {
      message.warning('请至少选择一条记录')
      return
    }

    // 为批量操作创建一个临时的当前记录对象
    currentRecord.value = {
      id: 'batch',
      pay_chnl: '',
      dateTime: '',
      accountId: 0,
      accountName: '批量修改',
      currency: '',
      collectionSubject: '',
      receivingBank: '',
      bankCardFourDigits: '',
      estimatedRevenue: '',
      expectedReceiptTime: '',
      actualInvoiceAmount: '',
      actualReceivedAmount: '',
      actualReceiptTime: '',
      receivedStatus: null,
      receivedResCode: null,
      receivedResMsg: null,
      createTime: '',
      updateTime: '',
      isNeedExternalSettlement: 0,
      balance: '',
      expectReceivedAmount: '',
      receivedDiff: '',
      receivedResExtInfo: {
        imageList: [],
        receivedResList: [],
      },
    }

    isBatchOperation.value = true
    otherReasonModalVisible.value = true
  }

  // 批量更新收款信息
  const handleBatchUpdateReceived = () => {
    if (selectedRowKeys.value.length === 0) {
      message.warning('请至少选择一条记录')
      return
    }

    try {
      Modal.confirm({
        title: '批量更新收款信息',
        content: `确定要更新${selectedRowKeys.value.length}条记录的收款信息吗？`,
        okText: '确定',
        cancelText: '取消',
        centered: true,
        onOk: async () => {
          const [err, res]: any = await awaitWrap(
            batchUpdateAccountBill({
              ids: selectedRowKeys.value,
              updateAccountInfo: true,
            }),
          )

          if (err) {
            console.log('[ err ] >', err)
            return
          }

          if (res && res.code === OK) {
            message.success(`成功更新${selectedRowKeys.value.length}条记录的收款信息`)
            emit('update-list')
          }
        },
      })
    } catch (error) {
      console.log('[ error ] >', error)
    }
  }

  // 批量修改实际收款时间
  const handleBatchModifyActualReceiptTime = () => {
    if (selectedRowKeys.value.length === 0) {
      message.warning('请至少选择一条记录')
      return
    }

    isBatchOperation.value = true
    editingField.value = 'actualReceiptTime'
    editingValue.value = null
    editFieldTitle.value = '批量修改实际收款时间'
    editFieldModalVisible.value = true
  }

  return {
    tableHeight,
    modalVisible,
    otherReasonModalVisible,
    currentRecord,
    originalRecord,
    textareaRef,
    editFieldModalVisible,
    editingField,
    editingValue,
    editFieldTitle,
    fileList,
    previewVisible,
    previewImage,
    previewTitle,
    changePage,
    handleImportSettlement,
    handleExportAccount,
    handleEditCustomReason,
    handleOtherReasonConfirm,
    handleOtherReasonCancel,
    handleEditField,
    handleEditFieldConfirm,
    handleEditFieldCancel,
    handleStatusChange,
    handleTableChange,
    handleBatchModify,
    handleBatchModifyStatus,
    handleBatchUpdateStatus,
    handleBatchUpdateExpected,
    handleBatchUpdateReceived,
    handleBatchModifyActualReceiptTime,
    selectedRowKeys,
    onSelectChange,
    isBatchOperation,
  }
}
