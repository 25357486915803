// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 账单列表查询请求参数类型
export interface AccountBillListParams {
  startMonthDate: string // 结算月开始时间 yyyy-MM
  endMonthDate: string // 结算月结束时间 yyyy-MM
  accountIdList: number[] // 账号id列表
  payChnl: string // 支付渠道
  pageNum?: number
  pageSize?: number
  currency?: string // 结算币种，USD CNY
  sortField?:
    | 'balance'
    | 'estimatedRevenue'
    | 'actualInvoiceAmount'
    | 'actualReceivedAmount'
    | 'expectReceivedAmount'
    | 'receivedDiff' // 排序字段
  isNeedExternalSettlement?: number // 是否外部结算 1(外部结算账号) 0(非外部结算账号)
  ascending?: boolean // 是否升序排序
}

// 账单列表项类型
export interface AccountBillItem {
  id: string // 主键id
  pay_chnl: string // 支付渠道
  dateTime: string // 结算月份 yyyy-MM
  accountId: number // 账号id
  accountName: string // 账号名称
  currency: string // 结算币种
  collectionSubject: string // 收款主体
  receivingBank: string // 收款银行
  bankCardFourDigits: string // 银行卡后四位
  estimatedRevenue: string // 预估收入
  expectedReceiptTime: string // 预计收款时间
  actualInvoiceAmount: string // 实际账单收入
  actualReceivedAmount: string // 实际回款金额
  actualReceiptTime: string // 实际收款时间
  receivedStatus: number | null // 回款状态
  receivedResCode: number | null // 收款备注码
  receivedResMsg: string | null // 收款备注信息
  createTime: string // 创建时间
  updateTime: string // 更新时间
  isNeedExternalSettlement: number // 是否需要外部结算
  balance: string // 账号余额
  expectReceivedAmount: string // 应回款金额
  receivedDiff: string // 回款差额
  receivedResExtInfo?: {
    imageList: string[] // 图片链接列表
    receivedResList: Array<{
      receivedResCode: any // 异常回款错误码
      receivedResMsg: string // 异常回款错误信息
    }>
  }
}

// 账单更新请求参数类型
export interface AccountBillUpdateParams {
  id?: string // 主键id
  ids?: string[] // 主键id列表
  expectedReceiptTime?: string // 预估收款时间
  actualReceivedAmount?: string // 实际回款金额
  actualReceiptTime?: string // 实际收款时间
  receivedStatus?: number // 回款状态
  balance?: string // 账号余额
  actualInvoiceAmount?: string // 实际账单收入
  updateAccountInfo?: boolean // 是否更新账号信息
  receivedResExtInfo?: {
    imageList: string[] // 图片链接列表
    receivedResList: Array<{
      receivedResCode: number // 收款备注码
      receivedResMsg: string // 收款备注信息
    }>
  }
}

// 任务添加请求参数类型
export interface TaskAddParams {
  list: Array<{
    reqParams: {
      currency: string // 货币
      accountId: number
      fileUrlList: string[] // 文件URL列表
    }
    taskType: number
    taskName: string
  }>
}

// 产品账单列表项类型
export interface ProductBillItem {
  amount: string
  appName: string
  billType: string
  commissionAmount: string
  currency: string
  dateTime: string
  dbtId: string
  orderAmount: string
  payChnl: string
  payChnlName: string
  refundAmount: string
  refundOrderAmount: string
  revenue: string
  tax: string
  billStatus: number // 结算状态
}

// 账号映射关系返回类型
export interface CurrencyAccountMapping {
  currency: string
  accountList: Array<{
    accountName: string
    accountId: number
  }>
}

// 获取账号列表参数类型
export interface GetAccountListParams {
  accountType: 1 | 2 // 1: 苹果, 2: 谷歌
}

// 账单列表查询
export const getAccountBillList = (data: AccountBillListParams) =>
  request('/payAdminServer/account/bill/list', null, 'POST_JSON', data)

// 账单更新
export const updateAccountBill = (data: AccountBillUpdateParams) =>
  request('/payAdminServer/account/bill/update', null, 'POST_JSON', data)

// 批量账单更新
export const batchUpdateAccountBill = (data: AccountBillUpdateParams) =>
  request('/payAdminServer/account/bill/batch/update', null, 'POST_JSON', data)

// 添加任务
export const addTask = (data: TaskAddParams) => request('/payAdminServer/bill/task/add', null, 'POST_JSON', data)

// 导出账单
export const exportAccountBill = (data: AccountBillListParams) =>
  request('/payAdminServer/account/bill/export', null, 'POST_DOWNLOAD', data)

// 获取产品账单列表
export const getProductBillList = (data: any) => request('/payAdminServer/bill/getBillList', null, 'POST_JSON', data)

// 获取货币和账号映射关系
export const getCurrencyAccountList = (data: any) =>
  request(`/payAdminServer/project/account/getCurrency2AccountList`, null, 'POST_JSON', data)

// 获取账号列表
export const getAccountList = (params: GetAccountListParams) =>
  request(`/payAdminServer/project/account/getAccountList`, null, 'POST_JSON', params)
